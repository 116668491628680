<template>
  <div>
    <v-autocomplete
      v-model="client.bdoType"
      :items="bdoTypes"
      class="mb-4"
      label="Logika BDO"
      placeholder="Wybierz logikę BDO"
      item-text="type"
      item-value="type"
      :rules="[rules.required]"
      append-icon="mdi-chevron-down"
      outlined
      dense
      @input="updateValues"
    />
    <v-text-field
      ref="controlNameInput"
      v-model="client.controlName"
      outlined
      label="Dane przekazującego"
      :rules="isControlNameRequired ? [rules.required] : []"
      placeholder="Wpisz dane przekazującego"
      @input="updateValues"
    />
  </div>
</template>

<script>
import rules from '../../../utils/validators'
import { mapState } from 'vuex'

export default {
  props: {
    data: {
      type: Object,
      default: () => {}
    }
  },
  data: () => ({
    client: {
      bdoType: null,
      controlName: null,
    },
    rules
  }),
  methods: {
    updateValues() {
      this.$emit('update', { controlName: this.client.controlName, bdoType: this.client.bdoType })
    }
  },
  computed: {
    ...mapState({
      filters: state => state.core.filters,
    }),
    bdoTypes() {
      return this.data.clientType === 'Firma'
        ? this.filters.clientBdoTypes.filter(type => type.company)
        : this.filters.clientBdoTypes.filter(type => !type.company)
    },
    isControlNameRequired() {
      return ['Oświadczenie', 'Zwolniony'].includes(this.client.bdoType)
    },
  },
  mounted () {
    const { controlName, bdoType } = this.data
    this.client.controlName = controlName
    this.client.bdoType = bdoType

    this.$nextTick().then(() => {
      this.$refs.controlNameInput.focus()
    })
  },
}
</script>
